<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <div class="w-100 text-center" v-if="isLoading">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <b-row class="relative" v-else>
            <b-col
              cols="12"
              class="d-flex justify-content-between align-items-center"
            >
              <h1>{{ detailParticipant.timeline.title }}</h1>
              <h4 class="text-capitalize">
                <b-badge
                  pill
                  variant="light-success"
                  class="text-capitalize"
                  v-if="detailParticipant.status.includes('passed')"
                >
                  {{
                    detailParticipant.status
                      ? detailParticipant.status
                          .replaceAll("-", " ")
                          .toLowerCase()
                      : ""
                  }}
                </b-badge>
                <b-badge
                  pill
                  variant="light-danger"
                  class="text-capitalize"
                  v-else-if="detailParticipant.status.includes('failed')"
                >
                  {{
                    detailParticipant.status
                      ? detailParticipant.status
                          .replaceAll("-", " ")
                          .toLowerCase()
                      : ""
                  }}
                </b-badge>
                <b-badge
                  pill
                  variant="light-warning"
                  class="text-capitalize"
                  v-else
                >
                  {{
                    detailParticipant.status
                      ? detailParticipant.status
                          .replaceAll("-", " ")
                          .toLowerCase()
                      : ""
                  }}
                </b-badge>
              </h4>
            </b-col>
            <b-col cols="12 mt-2">
              <h4 class="card-title">Personal Information</h4>
            </b-col>
            <b-col cols="12" class="mb-4">
              <div class="d-flex justify-content-center" v-if="personalInformation && personalInformation.information.photo">
                <img
                  :src="
                    personalInformation.information.photo
                      ? personalInformation.information.photo
                      : require(`@/assets/images/avatars/null-profile.webp`)
                  "
                  @error="
                    $event.target.src = require(`@/assets/images/avatars/no-image.png`)
                  "
                  :alt="personalInformation.information.name"
                  class="rounded-pill"
                  style="height: 150px; width: 150px; object-fit: cover"
                />
              </div>
              <div class="d-flex justify-content-center" v-else>
                <img
                  :src="require('@/assets/images/avatars/null-profile.webp')"
                  alt="avatar"
                  class="rounded-pill"
                  style="height: 150px; width: 150px; object-fit: cover"
                />
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Participant Name</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100" v-if="personalInformation && personalInformation.information.name">
                  {{ personalInformation.information.name }}
                </p>
                <p class="mb-0 w-100" v-else>
                  -
                </p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Birth Date</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100" v-if="personalInformation && personalInformation.information.birth_date">
                  {{ personalInformation.information.birth_date }}
                </p>
                <p class="mb-0 w-100" v-else>
                  -
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Email</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100" v-if="personalInformation && personalInformation.information.email">
                  {{ personalInformation.information.email }}
                </p>
                <p class="mb-0 w-100" v-else>
                  -
                </p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Phone Number</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100" v-if="personalInformation && personalInformation.information.phone_number">
                  {{ personalInformation.information.phone_number }}
                </p>
                <p class="mb-0 w-100" v-else>
                  -
                </p>
              </div>
            </b-col>

            <b-col cols="12">
              <h4 class="card-title mt-5">Education Information</h4>
            </b-col>
            <b-col cols="12" md="6" v-if="educationInformation">
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">University Name</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100">
                  {{ educationInformation.information.university.name }}
                </p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Faculty</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100">
                  {{ educationInformation.information.university.faculty }}
                </p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Department</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100">
                  {{ educationInformation.information.university.department }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6" v-if="educationInformation">
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">City</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100">
                  {{ educationInformation.information.university.city }}
                </p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">IPK</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100">
                  {{ educationInformation.information.last_ipk }}
                </p>
              </div>
            </b-col>

            <b-col cols="12">
              <h4 class="card-title mt-5">Organizational Experience</h4>
            </b-col>
            <template v-if="organizationalExperience">
              <b-col
                cols="12"
                md="6"
                v-for="(item, index) in organizationalExperience.information"
                :key="index"
              >
                <div
                  class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-50"
                  >
                    <h5 class="mb-0">Organization Name</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <p class="mb-0 w-100">
                    {{ item.name }}
                  </p>
                </div>
                <div
                  class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-50"
                  >
                    <h5 class="mb-0">Position</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <p class="mb-0 w-100">
                    {{ item.position }}
                  </p>
                </div>
                <div
                  class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-50"
                  >
                    <h5 class="mb-0">Division</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <p class="mb-0 w-100">
                    {{ item.division }}
                  </p>
                </div>
                <div
                  class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-50"
                  >
                    <h5 class="mb-0">Job Description</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <p class="mb-0 w-100">
                    {{ item.job_description }}
                  </p>
                </div>
                <div
                  class="d-flex align-items-start flex-column flex-md-row mb-2"
                  style="gap: 24px"
                >
                  <div
                    class="d-flex align-items-center justify-content-between w-50"
                  >
                    <h5 class="mb-0">Organization Description</h5>
                    <h5 class="mb-0">:</h5>
                  </div>
                  <p class="mb-0 w-100">
                    {{ item.organization_description }}
                  </p>
                </div>
              </b-col>
            </template>

            <b-col cols="12">
              <h4 class="card-title mt-5">Leadership Essay and Experience</h4>
            </b-col>
            <div v-if="leadershipEssayAndExperience">
              <b-col cols="12">
                <div class="mb-1">
                  <h5 class="mb-25">Leadership Experience</h5>
                  <p class="mb-0 w-100">
                    {{
                      leadershipEssayAndExperience.information
                        .leadership_experience
                    }}
                  </p>
                </div>
                <div class="mb-1">
                  <h5 class="mb-25">Essay Personal Statement</h5>
                  <p class="mb-0 w-100">
                    {{
                      leadershipEssayAndExperience.information
                        .essay_personal_statement
                    }}
                  </p>
                </div>
              </b-col>
            </div>

            <b-col cols="12">
              <h4 class="card-title mt-5">Requirements</h4>
            </b-col>
            <b-col cols="12">
              <b-table
                striped
                hover
                :items="detailTimeline.requirements"
                :fields="fieldsRequirements"
                :busy="isLoading"
                show-empty
                responsive
              >
                <template #table-busy>
                  <div class="text-center text-secondary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

                <template v-slot:cell(other.url)="{ item }">
                  <div>{{ item.other.url || "-" }}</div>
                </template>

                <template v-slot:cell(status)="{ item }">
                  <b-badge
                    v-if="item.status"
                    :variant="
                      item.status === 'complete'
                        ? 'success'
                        : item.status === 'on-progress'
                        ? 'warning'
                        : item.status === 'submitted'
                        ? 'info'
                        : 'danger'
                    "
                    class="text-capitalize"
                  >
                    {{ item.status.replaceAll("-", " ").toLowerCase() }}
                  </b-badge>
                  <div v-else>-</div>
                </template>

                <template v-slot:cell(actions)="{ item }">
                  <b-dropdown
                    v-if="
                      detailParticipant.current_requirement &&
                      item.code === detailParticipant.current_requirement.code
                    "
                    variant="danger"
                    size="sm"
                  >
                    <template #button-content> Action </template>
                    <b-dropdown-item
                      @click="
                        item.code === 'ENGLISH_AND_GMAT_TEST' ||
                        item.code === 'LEADERLESS_GROUP_DISCUSSION'
                          ? showApprovalModal()
                          : approve()
                      "
                    >
                      <feather-icon icon="CheckIcon" class="mr-75" />
                      Approve
                    </b-dropdown-item>

                    <b-dropdown-item @click="disapprove()">
                      <feather-icon icon="XIcon" class="mr-75" />
                      Disapprove
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>

              <b-col cols="12">
                <h4 class="card-title mt-5">E-Learning</h4>
              </b-col>
              <b-col cols="12">
                <b-table
                  striped
                  hover
                  :items="mergedClasses"
                  :fields="fieldsElearning"
                  :busy="isLoading"
                  show-empty
                  responsive
                >
                  <template v-slot:cell(image)="{ item }">
                    <img :src="item.image" class="image__table" alt="" />
                  </template>

                  <template v-slot:cell(status)="{ item }">
                    <span v-if="item.status === 'checked'">
                      <feather-icon class="ml-75" size="25" icon="CheckIcon" />
                    </span>
                    <span v-else class="ml-75">-</span>
                  </template>

                  <template v-slot:cell(actions)="{ item }">
                    <b-dropdown variant="danger" size="sm" v-if="item.status !== 'checked'">
                      <template #button-content> Action </template>
                      <b-dropdown-item
                        @click="setComplete(item)"
                      >
                        <feather-icon icon="CheckIcon" class="mr-75" />
                        Set Complete
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </b-col>

              <b-col cols="12">
                <h4 class="card-title mt-5">Schedule</h4>
              </b-col>
              <b-col cols="12">
                <b-table
                  striped
                  hover
                  :items="
                    detailParticipant.female_future_leaders.filter(
                      (ffl) =>
                        ffl.type == 'leaderless-group-discussion' ||
                        ffl.type == 'online-interview'
                    )
                  "
                  :fields="fieldsSchedule"
                  :busy="isLoading"
                  show-empty
                  responsive
                >
                  <template v-slot:cell(actions)="{ item }">
                    <b-dropdown variant="danger" size="sm">
                      <template #button-content> Action </template>
                      <b-dropdown-item @click="editItem(item)">
                        <feather-icon icon="EditIcon" class="mr-75" />
                        Update
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </b-col>

              <b-modal
                id="form-approval"
                centered
                :title="isEdit ? 'Update' : 'Approval'"
                size="lg"
                no-close-on-backdrop
                hide-footer
              >
                <div class="form">
                  <div class="form-group">
                    <label for="start-date">Start Date:</label>
                    <flat-pickr
                      id="start-date"
                      class="form-control"
                      v-model="formPayload.start_date"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:ss',
                        time_24hr: true,
                      }"
                    />
                  </div>
                  <div class="form-group">
                    <label for="end-date">End Date:</label>
                    <flat-pickr
                      id="end-date"
                      class="form-control"
                      v-model="formPayload.end_date"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:ss',
                        time_24hr: true,
                      }"
                    />
                  </div>
                  <div class="form-group">
                    <label for="url">Url:</label>
                    <input
                      id="url"
                      type="text"
                      class="form-control"
                      v-model="formPayload.url"
                    />
                    <small>*Harap isikan url online interview</small>
                  </div>
                </div>
                <b-button
                  variant="primary"
                  @click="isEdit ? updateSchedule() : approve()"
                >
                  {{ isEdit ? "Update" : "Approve" }}
                </b-button>
              </b-modal>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BButton,
  BRow,
  BSpinner,
  BCol,
  BFormFile,
  BFormCheckbox,
  BBadge,
  BDropdown,
  BDropdownItem,
  BTable,
  VBModal,
} from "bootstrap-vue";
import { successNotification, errorNotification } from "@/auth/utils";
export default {
  components: {
    BCard,
    BRow,
    BSpinner,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BFormFile,
    vSelect,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTable,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      currentPage: 1,
      timelineId: "",
      detailParticipant: {},
      detailTimeline: {},
      personalInformation: {},
      educationInformation: {},
      leadershipEssayAndExperience: {},
      organizationalExperience: {},
      result: [],
      formPayload: {
        status: "",
        start_date: "",
        end_date: "",
        url: "",
      },
      isLoading: false,
      fieldsRequirements: [
        { key: "name" },
        { key: "start_date" },
        { key: "end_date" },
        { key: "other.url", label: "url" },
        { key: "status" },
        { key: "actions" },
      ],
      fieldsElearning: [
        { key: "title" },
        { key: "image" },
        { key: "status" },
        { key: "actions" },
      ],
      fieldsSchedule: [
        { key: "type" },
        { key: "information.url", label: "Url" },
        { key: "information.start_date", label: "Start Date" },
        { key: "information.end_date", label: "End Date" },
        { key: "actions" },
      ],
      isEdit: false,
      validations: "",
      updateType: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.loadDetail();
  },
  computed: {
    mergedClasses() {
      const timelineClasses = this.detailTimeline.classes || [];
      const participantClasses = this.detailParticipant.classes || [];

      return timelineClasses.map((timelineClass) => {
        const participantClass = participantClasses.find(
          (participantClass) => participantClass.uuid === timelineClass.uuid
        );
        return {
          ...timelineClass,
          status: participantClass ? "checked" : timelineClass.status,
        };
      });
    },
  },
  methods: {
    cleanUpForm() {
      this.formPayload = {
        status: "",
        start_date: "",
        end_date: "",
        url: "",
      };
      this.updateType = "";
    },
    loadDetail() {
      const params = this.$route.params.uuid;
      this.$http
        .get("/api/v1/admin/female-future-leaders/participant/" + params)
        .then((response) => {
          this.detailParticipant = response.data.data;
          this.timelineId = response.data.data.timeline.uuid;
          this.personalInformation =
            this.detailParticipant.female_future_leaders.find(
              (item) => item.type === "personal-information"
            );
          this.educationInformation =
            this.detailParticipant.female_future_leaders.find(
              (item) => item.type === "education-information"
            );
          this.leadershipEssayAndExperience =
            this.detailParticipant.female_future_leaders.find(
              (item) => item.type === "leadership-essay-and-experience"
            );
          this.organizationalExperience =
            this.detailParticipant.female_future_leaders.find(
              (item) => item.type === "organizational-experience"
            );
          this.loadDetailTimeline();
        });
    },
    loadDetailTimeline() {
      const uuid = this.timelineId;
      this.$http.get("/api/v1/admin/ffl-timelines/" + uuid).then((response) => {
        this.detailTimeline = response.data.data;

        this.detailTimeline.requirements.forEach((timelineReq) => {
          const participantReq = this.detailParticipant.requirements.find(
            (req) => req.id === timelineReq.id
          );

          if (participantReq) {
            timelineReq.status = participantReq.status;
          }
        });
      });
    },
    showApprovalModal() {
      this.cleanUpForm();
      this.isEdit = false;
      this.$bvModal.show("form-approval");
    },
    editItem(item) {
      this.cleanUpForm();
      this.isEdit = true;
      this.formPayload = {
        status: item.type,
        start_date: item.information.start_date,
        end_date: item.information.end_date,
        url: item.information.url,
      };
      this.updateType = item.type;
      this.$bvModal.show("form-approval");
    },
    preparePayload() {
      const form = new URLSearchParams();
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key]) {
          form.append(key, this.formPayload[key]);
        }
      }
      return form;
    },
    approve() {
      this.formPayload.status =
        this.detailParticipant.current_requirement.statuses[1];

      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to approve this requirement?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          var payload = this.preparePayload();
          const params = this.$route.params.uuid;

          this.$http
            .post(
              `/api/v1/admin/female-future-leaders/participant/${params}/approval`,
              payload
            )
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "The requirement has been approved.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
              this.loadDetail();
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
    disapprove() {
      this.formPayload.status =
        this.detailParticipant.current_requirement.statuses[0];

      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to disapprove this requirement?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          var payload = this.preparePayload();
          const params = this.$route.params.uuid;

          this.$http
            .post(
              `/api/v1/admin/female-future-leaders/participant/${params}/approval`,
              payload
            )
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "The requirement has been disapproved.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
              this.loadDetail();
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
    updateSchedule() {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to update the schedule?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          var payload = this.preparePayload();
          const params = this.$route.params.uuid;
          const type = this.updateType;

          this.$http
            .post(
              `/api/v1/admin/female-future-leaders/participant/${params}/${type}`,
              payload
            )
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "The schedule has been updated.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
              this.loadDetail();
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
    setComplete(item) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to mark it as completed?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          const params = this.$route.params.uuid;

          this.$http
            .post(
              `/api/v1/admin/female-future-leaders/participant/${params}/mark-complete-class`,
              {
                class_uuid: item.uuid,
              }
            )
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "The E-learning has been mark as completed.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
              this.loadDetail();
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.image__table {
  width: 200px;
  height: 100px;
  object-fit: cover;
}
</style>
